import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@mui/material/Button"
import ArrowForwardThinIcon from "@mui/icons-material/ArrowForward"
import WaveSvg from "../assets/images/Wave.svg"
import Lottie from "react-lottie"
import AlfieFace from "../assets/animation/alfie-face"
import BreadCrumbs from "../components/bread-crumbs"
import "./meet-the-team.scss"

const breadCrumbsList = [
  {
    title: "Home",
    link: "/",
  },
]

class AboutUs extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Meet the team" />
        <BreadCrumbs
          currentPage={"Meet The Team"}
          breadCrumbsList={breadCrumbsList}
        />
        <div className="common-detailed-header">
          <div className="common-detailed-inner">
            <div className="common-detailed-pattern-content">
              <h2 className="common-detailed-main-head">Meet The Team</h2>
              <p className="common-detailed-para">
                Technology amplifies but does not replace high-quality care. At
                it’s core, Confidant is a community of integrated behavioral
                health providers and digital healthcare experts that use
                technology to maximize their impact.
              </p>
            </div>
            <div className="view-all-team-side">
              <div className="view-all-team-inner">
                <img
                  className="view-all-team-img"
                  src={require("../assets/images/team-illustrated-small.png")}
                  alt="Faces"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="common-wave-wrapper meet-team"
          style={{ backgroundImage: `url(${WaveSvg})` }}
        >
          <div className="wave-bg">
            <div className="common-wave-inner">
              <h2 className="common-wave-heading">Core Values</h2>
              <h2 className="common-wave-des">
                Confidant Health is a pace-setting digital health company, but
                at our core we are all about people. Our team strives to be:
              </h2>
              <div className="core-value-list">
                <div className="single-core-value">
                  <img
                    className="core-value-icon"
                    src={require("../assets/images/collaboration-icon.svg")}
                    alt="Icon"
                  />
                  <h2 className="core-value-head">Collaborative</h2>
                  <p className="core-value-text">
                    The best way to help people is by putting our brains
                    together. We encourage and celebrate collaboration across
                    disciplines and teams.
                  </p>
                </div>
                <div className="single-core-value">
                  <img
                    className="core-value-icon"
                    src={require("../assets/images/hold-heart.svg")}
                    alt="Icon"
                  />
                  <h2 className="core-value-head">Compassionate</h2>
                  <p className="core-value-text">
                    We are here to help people. That is our #1 priority and
                    we're in it together. That means helping each other thrive
                    as well as our clients.
                  </p>
                </div>
                <div className="single-core-value">
                  <img
                    className="core-value-icon"
                    src={require("../assets/images/compass1-icon.svg")}
                    alt="Icon"
                  />
                  <h2 className="core-value-head">Honorable</h2>
                  <p className="core-value-text">
                    We follow our moral compass and always come back to
                    integrity. Our mission will always be more important than
                    money.
                  </p>
                </div>
                <div className="single-core-value">
                  <img
                    className="core-value-icon"
                    src={require("../assets/images/user-target.svg")}
                    alt="Icon"
                  />
                  <h2 className="core-value-head">Outcome Oriented</h2>
                  <p className="core-value-text">
                    We are here to help our clients achieve the goals they set
                    for themselves using a combination of the best clinical
                    tools, resources, and technology.{" "}
                  </p>
                </div>
                <div className="single-core-value">
                  <img
                    className="core-value-icon"
                    src={require("../assets/images/knight-icon.svg")}
                    alt="Icon"
                  />
                  <h2 className="core-value-head">Open Minded</h2>
                  <p className="core-value-text">
                    We're consciously looking for the future of treatment and
                    technology. We strive to identify and evaluate "what's next"
                    and aren't stuck in "what's now".
                  </p>
                </div>
                <div className="single-core-value">
                  <img
                    className="core-value-icon"
                    src={require("../assets/images/libra-icon.svg")}
                    alt="Icon"
                  />
                  <h2 className="core-value-head">Principled</h2>
                  <p className="core-value-text">
                    We are here to stand for the future of the healthcare
                    experience. We believe in transparency, patient centered
                    care, and provider decision making based on efficacy, not
                    billing.
                  </p>
                </div>
              </div>

              <div className="team-wrap">
                <div className="team-inner">
                  <h2 className="blackHeading2">MEET THE TEAM</h2>
                  <div className="team-list">
                    <div id="single-member-t1" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/T1.png")}
                        alt="single"
                      />
                      <p className="mem-name">Jon Read</p>
                      <p className="mem-des">Founder</p>
                    </div>
                    <div id="single-member-t2" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/T2.png")}
                        alt="single"
                      />
                      <p className="mem-name">Sam Arsenault Wilson</p>
                      <p className="mem-des">
                        Chief Quality Officer and Co-Founder
                      </p>
                    </div>
                    {/*<div id="single-member-t3"
                                             className="single-member">
                                            <img className="mem-img"
                                                 src={require(
                                                     '../assets/images/T3.png')}
                                                 alt="single"/>
                                            <p className="mem-name">Dr. Mishka
                                                Terplan</p>
                                            <p className="mem-des">Medical
                                                Director</p>
                                        </div>*/}
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/T4.png")}
                        alt="single"
                      />
                      <p className="mem-name">Hannah Biggie</p>
                      <p className="mem-des">Content</p>
                    </div>
                    <div id="single-member-t5" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/T5.png")}
                        alt="single"
                      />
                      <p className="mem-name">Dr. Teresa Spencer</p>
                      <p className="mem-des">Clinical Care</p>
                    </div>
                    <div id="single-member-t6" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/T6.png")}
                        alt="single"
                      />
                      <p className="mem-name">Dr. Sherry Lucke</p>
                      <p className="mem-des">Clinical Care</p>
                    </div>
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/chelsea.png")}
                        alt="single"
                      />
                      <p className="mem-name">Chelsea Cyr</p>
                      <p className="mem-des">Coach</p>
                    </div>
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/jim.png")}
                        alt="single"
                      />
                      <p className="mem-name">Jim Higgins</p>
                      <p className="mem-des">Coach</p>
                    </div>
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/Sharon-Hanford.png")}
                        alt="single"
                      />
                      <p className="mem-name">Sharon Hanford</p>
                      <p className="mem-des">Coach</p>
                    </div>
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/Randy-Gratton.png")}
                        alt="single"
                      />
                      <p className="mem-name">Randy Gratton</p>
                      <p className="mem-des">Coach</p>
                    </div>
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/sarah-geary.png")}
                        alt="single"
                      />
                      <p className="mem-name">Sarah Geary</p>
                      <p className="mem-des">Social Worker</p>
                    </div>
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/Donna-DeLuca.png")}
                        alt="single"
                      />
                      <p className="mem-name">Donna DeLuca</p>
                      <p className="mem-des">Family Matchmaker</p>
                    </div>
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/neema-amini.png")}
                        alt="single"
                      />
                      <p className="mem-name">Neema Amini</p>
                      <p className="mem-des">Legal Counsel</p>
                    </div>
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/dhana-small.png")}
                        alt="single"
                      />
                      <p className="mem-name">Dhana Small</p>
                      <p className="mem-des">Marketing Intern </p>
                    </div>
                    <div id="single-member-t4" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/tom-tyrrell.png")}
                        alt="single"
                      />
                      <p className="mem-name">Tom Tyrrell</p>
                      <p className="mem-des">Matchmaker & Counselor</p>
                    </div>

                    <div id="single-member-alfie" className="single-member">
                      <div className="alfie-ring">
                        <div className="inner-ring">
                          <Lottie
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: AlfieFace,
                            }}
                            height={200}
                            width={200}
                          />
                        </div>
                      </div>
                      <p className="mem-name">Alfie</p>
                      <p className="mem-des">Guide</p>
                    </div>
                    <div id="single-member-t7" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/T7.png")}
                        alt="single"
                      />
                      <p className="mem-name">You</p>
                      <p className="mem-des">Decision Maker</p>
                    </div>
                    <div id="single-member-t8" className="single-member">
                      <img
                        className="mem-img"
                        src={require("../assets/images/T8.png")}
                        alt="single"
                      />
                      <p className="mem-name">Matthew Deluca</p>
                      <p className="mem-des">Network</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="provider-dollar-wrap">
                <div className="provider-dollar-inner">
                  <img
                    className="provider-dollar-image"
                    src={require("../assets/images/dollar-pro.svg")}
                    alt="Provider"
                  />
                  <h2 className="provider-dollar-head">
                    We’re building a team that believes the same things we do
                  </h2>
                  <p className="provider-dollar-para">
                    If this sounds like you, come along for the journey.
                  </p>
                  <Button
                    href="/diversity/"
                    target="_blank"
                    className="new-blue-link"
                    color="primary"
                  >
                    Diversity, equity, and inclusion is a priority at Confidant
                    <ArrowForwardThinIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutUs
